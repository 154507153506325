<template>
  <div class="products">
    <div class="product-left">
      <ul>
        <li>Rack Server</li>
        <li>Tower Server</li>
        <li>Modular Server</li>
        <li>Rugged Server</li>
        <li>Blade Server</li>
        <li>High-performance SAN Storage Server</li>
      </ul>
    </div>
    <div class="product-right">
      <ul>
        <li>Network Attached Storage Server</li>
        <li>Direct Attached Storage Server</li>
        <li>Server Equipment and Accessories</li>
      </ul>
    </div>
  </div>
  <div class="products-vendor">
    <div class="logo">
      <img :src="require('@/assets/images/products/Computing.png')" alt="" />
    </div>
  </div>
</template>

<script></script>
