<template>
    <div class="products">
        <div class="product-left">
            <ul>
                <li>Network Rack Cabinet</li>
                <li>Server Rack Cabinet</li>
                <li>Open Racks</li>
                <li>Wall-Mounted Cabinets</li>
            </ul>
        </div>
        <div class="product-right">
            <ul>
                <li>KVM Switches Console</li>
                <li>Online UPS</li>
                <li>Power Distribution Unit</li>
                <li>Cabinet Rack Accessories</li>
            </ul>
        </div>
    </div>
    <div class="products-vendor rack">
        <div class="logo">
            <img :src="require('@/assets/images/products/Rack.png')" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style  scoped>
 
</style>
