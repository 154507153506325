<template>
    <div class="products">
        <div class="product-left">
            <ul>
                <li>UnManage Switch</li>
                <li>L2 Manage Switch</li>
                <li>L3 Manage Switch</li>
                <li>Fixed Stackable Switch</li>
                <li>Modular Switch (Enterprise-Class Midsize)</li>
                <li>Cloud-Manage Switch</li>
            </ul>
        </div>
        <div class="product-right">
            <ul>
                <li>Router</li>
                <li>Wireless Router</li>
                <li>VPN Router</li>
                <li>Cloud Edge Router</li>
                <li>Banch Router (Combine SD-WAN)</li>
                <li>Access Point</li>
                <li>Wireless Controller</li>
                <li>Cloud Manage Wireless Controller</li>
                <li>Load Balancer</li>
            </ul>
        </div>
    </div>
    <div class="products-vendor">
        <div class="logo">
            <img :src="require('@/assets/images/products/Networking.png')" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style >
   
</style>
