<template>
    <div class="products">
        <div class="product-left">
            <ul>
                <li>Video Conferencing</li>
                <li>Meeting Room Conferencing Camera</li>
                <li>Interactive Display & Whiteboard</li>
                <li>Camera & Speakerphone</li>
                <li>Personal Solution & Webcams</li>
                <li>Wireless Presentation System</li>
            </ul>
        </div>
        <div class="product-right">
            <ul>
                <li>IP Conference Phone</li>
                <li>USB/Bluetooth Speakerphone</li>
                <li>Wireless Earbuds</li>
                <li>Bluetooth Headsets</li>
            </ul>
        </div>
    </div>
    <div class="products-vendor">
        <div class="logo">
            <img :src="require('@/assets/images/products/Collaboration.png')" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>
