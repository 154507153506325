<template>
    <div class="products">
        <div class="product-left">
            <ul>
                <li>Next-Generation Firewall</li>
                <li>Virtual Next-Generation Firewall</li>
                <li>Web Application Firewall</li>
                <li>Ruggedized Network Firewalls</li>
            </ul>
        </div>
        <div class="product-right">
            <ul>
                <li>Cloud-Native Firewall</li>
                <li>Secure SD-Wan</li>
                <li>DDOS Attack Protection</li>
                <li>Mail Security Firewall</li>
            </ul>
        </div>
    </div>
    <div class="products-vendor security">
        <div class="logo">
            <img :src="require('@/assets/images/products/Security.png')" alt="">
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>


   
</style>
